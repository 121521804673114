(function () {
    'use strict';

    function UserProfileDashbordCtrl($location) {
        /* jshint validthis:true */
        var vm = this;
    }

    angular
        .module('app')
        .controller('UserProfileDashbordCtrl', UserProfileDashbordCtrl)

        UserProfileDashbordCtrl.$inject = ['$location'];
})();