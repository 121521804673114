
(function () {
    'use strict';

    var $cookies;

    var setCookie = function (key, value) {
        $cookies.putObject(key, value);
    };
    var getCookie = function (key, defaultValue) {
        var value = $cookies.getObject(key);
        return angular.isUndefined(value) ? defaultValue : value;
    };
    var clearCookie = function (key) {
        $cookies.put(key, undefined);
        $cookies.remove(key);
    };

    var service = function (_$cookies) {
        $cookies = _$cookies;
        return {
            'set': setCookie,
            'get': getCookie,
            'clear': clearCookie
        }
    };

    angular
        .module('app')
        .service('cookieService', ['$cookies', service]);
})();