(function () {
    'use strict';

    angular
        .module('app')
        .controller('QuickSidebarController', QuickSidebarController)

    /** @ngInject */
    function QuickSidebarController($scope) {
        var vm = this;
        $scope.$on('$includeContentLoaded', function () {
            setTimeout(function () {
                QuickSidebar.init(); // init quick sidebar        
            }, 2000);
        });
    }

}());