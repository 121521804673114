(function () {
    'use strict';

    function VerifyUserCtrl($stateParams, $state, userService) {
        var vm = this;
        vm.isVerified = false;
        if ($stateParams.key) {
            userService.verifyToken($stateParams.key).then(function (resp) {
                vm.isVerified = true;
            }, function (error) {
                vm.isVerified = false;
            });
        }

        function redirectTologin() {
            $state.go('login');
        }
        vm.redirectTologin = redirectTologin;
    }
    angular
        .module('app')
        .controller('VerifyUserCtrl', VerifyUserCtrl);

    VerifyUserCtrl.$inject = ['$stateParams', '$state', 'userService'];
})();