(function () {
    'use strict';

    angular
        .module('app')
        .controller('FooterController', FooterController)

    /** @ngInject */
    function FooterController($scope) {
        var vm = this;
        $scope.$on('$includeContentLoaded', function () {
            Layout.initFooter(); // init footer
        });
    }

}());