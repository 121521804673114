var App = angular.module('app', [
    'ui.router',
    'ui.bootstrap',
    'oc.lazyLoad',
    'ngSanitize',
    'ngCookies',
    'ngStorage',
    'blockUI',
    'ng-backstretch',
    'ui-notification'
]);

/* Configure ocLazyLoader(refer: https://github.com/ocombe/ocLazyLoad) */
App.config(['$ocLazyLoadProvider', function ($ocLazyLoadProvider) {
    $ocLazyLoadProvider.config({
        // global configs go here
    });
}]);

//AngularJS v1.3.x workaround for old style controller declarition in HTML
App.config(['$controllerProvider', function ($controllerProvider) {
    // this option might be handy for migrating old apps, but please don't use it
    // in new ones!
    $controllerProvider.allowGlobals();
}]);
/* Setup global settings */
App.factory('settings', ['$rootScope', function ($rootScope) {
    // supported languages
    var settings = {
        layout: {
            pageSidebarClosed: false, // sidebar menu state
            pageContentWhite: true, // set page content layout
            pageBodySolid: false, // solid body color state
            pageAutoScrollOnLoad: 1000, // auto scroll to top on page load
            pageHeader: false,
            pageSidebar: false,
            pageFooter: false,
        },
        assetsPath: 'assets',
        globalPath: 'assets/global',
        layoutPath: 'assets/layouts/layout',
    };

    $rootScope.settings = settings;

    return settings;
}]);

/* Setup App Main Controller */
App.controller('AppController', ['$scope', '$rootScope', function ($scope, $rootScope) {
    $scope.$on('$viewContentLoaded', function () {
        App.initComponents(); // init core components
        Layout.init(); //  Init entire layout(header, footer, sidebar, etc) on page load if the partials included in server side instead of loading with ng-include directive 
    });
}]);

App.controller('MainCtrl', ['$scope', '$rootScope', '$state', 'authService', function ($scope, $rootScope, $state, authService) {
    if (authService.getUser()) {
        if (authService.getStateInMemory()) {
            var stateObj = authService.getStateInMemory();
            $state.go(stateObj.state, stateObj.params);
        }
        else {
            $state.go('dashboard');
        }
    }
    else {
        $state.go('login');
    }
}]);

/* Configure blockUI */
App.config(['blockUIConfig', function (blockUIConfig) {
    blockUIConfig.requestFilter = function (config) {
        //Perform a global, case-insensitive search on the request url for 'noblockui' ...
        if (config.url.match('#noload')) {
            return false; // ... don't block it.
        }
    };
}]);

App.config(function (NotificationProvider) {
    NotificationProvider.setOptions({
        delay: 10000,
        startTop: 20,
        startRight: 10,
        verticalSpacing: 20,
        horizontalSpacing: 20,
        positionX: 'right',
        positionY: 'top'
    });
});


// Constant
App.constant('API_URL', 'http://13.127.72.209/api/');

/***
Layout Partials.
By default the partials are loaded through AngularJS ng-include directive. In case they loaded in server side(e.g: PHP include function) then below partial 
initialization can be disabled and Layout.init() should be called on page load complete as explained above.
***/

/* Init global settings and run the app */
App.run(['$rootScope', 'settings', '$state', '$http', 'authService', '$transitions', function ($rootScope, settings, $state, $http, authService, $transitions) {
    $rootScope.$state = $state; // state to be accessed from view
    $rootScope.$settings = settings; // state to be accessed from view
    if (authService.getToken()) {
        $http.defaults.headers.common.Authorization = 'Bearer ' + authService.getToken(); // inject token into header for all $http call
    }
    $http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; // inject header for all $http post
    $http.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded'; // inject header for all $http put
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    $transitions.onSuccess({}, function ($transition) {
        $rootScope.previousState = $transition.$from().name;
        if ($transition.$from().params.id || $transition.$from().params.page) {
            $rootScope.previousParam = $transition.$from().params;
        } else {
            $rootScope.previousParam = false;
        }
        $rootScope.currentState = $transition.$to().name;
    });

    $transitions.onStart({}, function ($transition) {
        if (authService.getToken() === '' && $transition.$to().data.authorize) {
            event.preventDefault();
            authService.setStateInMemory({
                state: $transition.$to().name,
                params: $transition.$to().params
            });
            $rootScope.$broadcast('forceHideSpinnerBar');
            $state.go('login');
        }
    });

    $rootScope.goBack = function () {
        if ($rootScope.previousState) {
            if ($rootScope.previousParam) {
                $state.go($rootScope.previousState, $rootScope.previousParam);
            } else {
                $state.go($rootScope.previousState);
            }
        } else {
            $state.go($localStorage.defaultRoute);
        }
    };

}]);

/***
GLobal Directives
***/

// Route State Load Spinner(used on page or content load)
App.directive('ngSpinnerBar', ['$rootScope', '$state',
    function ($rootScope, $state) {
        return {
            link: function (scope, element, attrs) {
                // by defult hide the spinner bar
                element.addClass('hide'); // hide spinner bar by default

                // display the spinner bar whenever the route changes(the content part started loading)
                $rootScope.$on('$stateChangeStart', function () {
                    element.removeClass('hide'); // show spinner bar
                });

                // hide the spinner bar on rounte change success(after the content loaded)
                $rootScope.$on('$stateChangeSuccess', function (event) {
                    element.addClass('hide'); // hide spinner bar
                    $('body').removeClass('page-on-load'); // remove page loading indicator
                    Layout.setAngularJsSidebarMenuActiveLink('match', null, event.currentScope.$state); // activate selected link in the sidebar menu

                    // auto scorll to page top
                    setTimeout(function () {
                        App.scrollTop(); // scroll to the top on content load
                    }, $rootScope.settings.layout.pageAutoScrollOnLoad);
                });

                // handle errors
                $rootScope.$on('$stateNotFound', function () {
                    element.addClass('hide'); // hide spinner bar
                });

                // handle errors
                $rootScope.$on('$stateChangeError', function () {
                    element.addClass('hide'); // hide spinner bar
                });
            }
        };
    }
])

// Handle global LINK click
App.directive('a', function () {
    return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
            if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
                elem.on('click', function (e) {
                    e.preventDefault(); // prevent link click for above criteria
                });
            }
        }
    };
});

// Handle Dropdown Hover Plugin Integration
App.directive('dropdownMenuHover', function () {
    return {
        link: function (scope, elem) {
            elem.dropdownHover();
        }
    };
});

App.directive("compareTo", function () {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareTo"
        },
        link: function (scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function (modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function () {
                ngModel.$validate();
            });
        }
    };
});