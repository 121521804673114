angular.module('app').controller('DashboardController', function ($rootScope, $scope, $http, $timeout) {
    $scope.$on('$viewContentLoaded', function () {
        // initialize core components
      App.initAjax();
    });

    // set sidebar closed and body solid layout mode
    $rootScope.settings.layout.pageContentWhite = true;
    $rootScope.settings.layout.pageBodySolid = false;
    $rootScope.settings.layout.pageSidebarClosed = false;
    $rootScope.settings.layout.pageSidebarHide = true;
    $rootScope.settings.layout.pageHeader = true;
    $rootScope.settings.layout.pageSidebar = true;
    $rootScope.settings.layout.pageFooter = true;
});