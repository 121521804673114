(function () {
    'use strict';
    angular
        .module('app')
        .controller('SidebarController', SidebarController)

    /** @ngInject */
    function SidebarController($scope) {
        var vm = this;
        $scope.$on('$includeContentLoaded', function () {
            Layout.initSidebar(); // init sidebar
        });
    }
}());