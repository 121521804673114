(function () {
    'use strict';

    angular
        .module('app')
        .factory('apiService', apiService);

    apiService.$inject = ['$http', '$q', '$httpParamSerializerJQLike', '$timeout', 'API_URL', 'authService', 'blockUI'];

    function apiService($http, $q, $httpParamSerializerJQLike, $timeout, API_URL, authService, blockUI) {
        var service = {
            post: post
        };
        
        return service;

        function post(route, request, type, noBlock) {
            var deferred = $q.defer();
            var contentType = null;
            var data = null;
            if (noBlock) {
                route += '#noload';
            }
            if (type) {
                data = request;
                if (type == "json") {
                    contentType = 'application/json';
                } else if (type == "formData") {
                    contentType = 'multipart/form-data';
                }
            } else {
                data = $httpParamSerializerJQLike(request);
                contentType = 'application/x-www-form-urlencoded';
            }
            return $http({
                method: 'POST',
                url: API_URL + route,
                data: data,
                headers: {
                    'Content-Type': contentType
                }
            }).then(function (success) {
                deferred.resolve(success);
                return deferred.promise;
            }, function (error) {
                deferred.reject(error);
                return deferred.promise;
            });
            // .success(function (response) {
            //     deferred.resolve(response);
            // })
            // .error(function () {
            //     deferred.reject(arguments);
            // });
        }
    }
})();