(function () {
    'use strict';

    function LoginCtrl($scope, $rootScope, $state, authService, userService, blockUI, Notification) {
        /* jshint validthis:true */
        var vm = this;
        vm.forgetpasswordClicked = false;
        if (authService.getUser()) {
            $state.go('main');
        }
        $rootScope.settings.layout.pageContentWhite = true;
        $rootScope.settings.layout.pageBodySolid = false;
        $rootScope.settings.layout.pageSidebarClosed = false;
        $rootScope.settings.layout.pageHeader = false;
        $rootScope.settings.layout.pageSidebar = false;
        $rootScope.settings.layout.pageFooter = false;
        vm.loginData = {};
        vm.email = null;

        function login() {
            blockUI.start();
            $rootScope.activatedMessage = null;
            vm.forgotPasswordSuccess = null;
            vm.loginError = false;

            userService.login(vm.loginData.username, vm.loginData.password).then(function (response) {
                if (response.data.success) {
                    if (!angular.isUndefined(response.data.token) && !angular.isUndefined(response.data)) {
                        authService.setToken(response.data.token);
                        authService.setUser(response.data.user);
                        $state.go('main');
                        $rootScope.$broadcast('user-logged-in');
                        Notification.success(response.data.message);
                    }
                }
                blockUI.stop();
            }, function (response) {
                Notification.error(response.data.message);
                authService.clearToken();
                authService.clearUser();
                blockUI.stop();
            });
        }

        function goBack() {
            vm.forgetpasswordClicked = false;
        }

        function forgotPassword() {
            if (vm.email) {
                var data = {
                    'email': vm.email
                };
                userService.isEmailExist(data).then(function (resp) {
                    Notification.success(resp.data.message);
                    vm.forgetpasswordClicked = false;
                }, function (resp) {
                    Notification.error(resp.data.message);
                });
            }
        }

        vm.login = login;
        vm.goBack = goBack;
        vm.forgotPassword = forgotPassword;
    }

    angular
        .module('app')
        .controller('LoginCtrl', LoginCtrl);

    LoginCtrl.$inject = ['$scope', '$rootScope', '$state', 'authService', 'userService', 'blockUI', 'Notification'];
})();