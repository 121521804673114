(function () {
    'use strict';

    function UserProfileCtrl($scope, $state, $location, $rootScope) {
        var vm = this;
        $scope.$on('$viewContentLoaded', function () {
            App.initAjax(); // initialize core components
            Layout.setAngularJsSidebarMenuActiveLink('set', $('#sidebar_menu_link_profile'), $state); // set profile link active in sidebar menu 
        });
        $rootScope.settings.layout.pageContentWhite = true;
        $rootScope.settings.layout.pageBodySolid = true;
        $rootScope.settings.layout.pageSidebarClosed = false;
        $rootScope.settings.layout.pageSidebarHide = true;
        $rootScope.settings.layout.pageHeader = true;
        $rootScope.settings.layout.pageSidebar = true;
        $rootScope.settings.layout.pageFooter = true;
    }

    angular
        .module('app')
        .controller('UserProfileCtrl', UserProfileCtrl)

    UserProfileCtrl.$inject = ['$scope', '$state', '$location', '$rootScope'];
})();