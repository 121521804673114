(function () {
    'use strict';

    var cookieService, apiService, tokenKey, userKey, $http, $localStorage, state;

    var setToken = function (token) {
        cookieService.set(tokenKey, token);
        $http.defaults.headers.common.Authorization = 'Bearer ' + token; // inject token into header for all $http call
    };

    var getToken = function () {
        return cookieService.get(tokenKey, '');
    };

    var clearToken = function () {
        cookieService.clear(tokenKey);
    };

    var setUser = function (user) {
        $localStorage.user = user;
    };

    var getUser = function () {
        return $localStorage.user;
    };

    var clearUser = function () {
        $localStorage.user = null;
    };

    var setStateInMemory = function (toState) {
        this.state = toState;
    };

    var getStateInMemory = function () {
        return this.state;
    };

    var isLoggedUser = function (userId) {
        var loggedUser = getUser();
        if (loggedUser.id === userId) {
            return true;
        }
        return false;
    };

    var authService = function (_cookieService, _$http, _$localStorage) {
        cookieService = _cookieService;
        $http = _$http;
        $localStorage = _$localStorage;
        state = {};
        return {
            'setToken': setToken,
            'getToken': getToken,
            'clearToken': clearToken,
            'setUser': setUser,
            'getUser': getUser,
            'clearUser': clearUser,
            'setStateInMemory': setStateInMemory,
            'getStateInMemory': getStateInMemory,
            'isLoggedUser': isLoggedUser
        };
    };

    angular
        .module('app')
        .service('authService', ['cookieService', '$http', '$localStorage', authService]);

})();