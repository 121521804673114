(function () {
    'use strict';

    function userService(apiService) {
        return {
            login: login,
            register: register,
            verifyToken: verifyToken,
            isEmailExist: isEmailExist,
            isvalidEmailToken: isvalidEmailToken,
            resetPassword: resetPassword,

        };

        /**
         * Authenticate user for login
         */
        function login(username, password) {
            return apiService.post('login', {
                'email': username,
                'password': password
            });
        }

        /**
         * user object for register new user in the system.
         * @param {*object} data 
         */
        function register(data) {
            return apiService.post('register', data);
        };

        /**
         * verify token from email
         * @param {*string} token
         */
        function verifyToken(token) {
            return apiService.post('verify-email/' + token);
        }
        /**
         * is email exist check
         * @param {*string} email
         */
        function isEmailExist(data) {
            return apiService.post('forgot-password', data);
        }

        /**
         * check if email token is valid
         * @param {*string} token
         */

        function isvalidEmailToken(token) {
            return apiService.post('forgot-password/' + token);
        }

        /**
         * reset password
         * @param {*object} data
         */

        function resetPassword(data) {
            console.log(data);
            return apiService.post('reset-password/', data);
        }
    }

    angular
        .module('app')
        .factory('userService', userService);

    userService.$inject = ['apiService'];
})();