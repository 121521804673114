angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('main', {
      url: "/",
      template: " ",
      data: { pageTitle: 'Admin Dashboard Template' },
      controller: "MainCtrl"
    })
    .state('dashboard', {
      url: "/dashboard",
      templateUrl: "app/core/dashboard/dashboard.html",
      data: { pageTitle: 'Admin Dashboard Template', authorize: true },
      controller: "DashboardController",
      controllerAs: 'dashboardController',
    })
    .state('registration', {
      url: "/registration",
      templateUrl: "app/modules/user-management/registration/registration.html",
      data: { pageTitle: 'Admin Dashboard Template', authorize: false },
      controller: "RegistrationCtrl",
      controllerAs: 'registrationCtrl',
      resolve: {
        deps: ['$ocLazyLoad', function ($ocLazyLoad) {
          return $ocLazyLoad.load({
            name: 'app',
            insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
            files: [
              'assets/pages/css/login-5.css',
            ]
          });
        }]
      }
    })
    .state('login', {
      url: "/login",
      templateUrl: "app/modules/user-management/login/login.html",
      data: { pageTitle: 'Admin Dashboard Template', authorize: false },
      controller: "LoginCtrl",
      controllerAs: 'loginCtrl',
      resolve: {
        deps: ['$ocLazyLoad', function ($ocLazyLoad) {
          return $ocLazyLoad.load({
            name: 'app',
            insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
            files: [
              'assets/pages/css/login-5.css',
            ]
          });
        }]
      }
    })
    .state('forgot-password', {
      url: "/forgot-password/:key",
      templateUrl: "app/modules/user-management/forgot-password/forgot-password.html",
      data: { pageTitle: 'Admin Dashboard Template', authorize: false },
      controller: "ForgotPasswordCtrl",
      controllerAs: 'forgotPasswordCtrl',
      resolve: {
        deps: ['$ocLazyLoad', function ($ocLazyLoad) {
          return $ocLazyLoad.load({
            name: 'app',
            insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
            files: [
              'assets/pages/css/login-5.css',
            ]
          });
        }]
      }
    })
    .state('verify', {
      url: "/verify/:key",
      templateUrl: "app/modules/user-management/verify/verify-user.html",
      data: { pageTitle: 'Admin Dashboard Template', authorize: false },
      controller: "VerifyUserCtrl",
      controllerAs: 'verifyUserCtrl',
      resolve: {
        deps: ['$ocLazyLoad', function ($ocLazyLoad) {
          return $ocLazyLoad.load({
            name: 'app',
            insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
            files: [
              'assets/pages/css/login-5.css',
            ]
          });
        }]
      }
    })
    .state('profile', {
      url: "/profile",
      templateUrl: "app/modules/user-management/profile/user-profile.html",
      data: { pageTitle: 'Admin Dashboard Template', authorize: true },
      controller: "UserProfileCtrl",
      controllerAs: 'userProfileCtrl',
      resolve: {
        deps: ['$ocLazyLoad', function ($ocLazyLoad) {
          return $ocLazyLoad.load({
            name: 'app',
            insertBefore: '#ng_load_plugins_before', // load the above css files before a LINK element with this ID. Dynamic CSS files must be loaded between core and theme css files
            files: [
              'assets/pages/css/profile.css',
              'assets/pages/scripts/profile.js'
            ]
          });
        }]
      }
    })
    .state("profile.dashboard", {
      url: "/dashboard",
      controller: "UserProfileAccountCtrl",
      controllerAs: 'upaCtrl',
      templateUrl: "app/modules/user-management/profile/user-profile-dashbord.html",
      data: { pageTitle: 'User Profile Dashbord' }
    })
    .state("profile.account", {
      url: "/account",
      controller: "UserProfileCtrl",
      controllerAs: 'upCtrl',
      templateUrl: "app/modules/user-management/profile/user-profile-account.html",
      data: { pageTitle: 'User Profile Dashbord' }
    });
}
