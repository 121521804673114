(function () {
    'use strict';

    function ForgotPasswordCtrl($stateParams, userService, $state, Notification) {
        var vm = this;
        vm.forgotPasswordData = {};
        if ($stateParams.key) {
            userService.isvalidEmailToken($stateParams.key).then(function (resp) {
                vm.isVerified = true;
            }, function (resp) {
                Notification.error(resp.data.message);
                vm.isVerified = false;
                $state.go('login');
            });
        }

        function submit() {
            vm.forgotPasswordData.token = $stateParams.key;
            userService.resetPassword(vm.forgotPasswordData).then(function (resp) {
                Notification.sucess(resp.data.messages);
                $state.go('login');
            }, function (resp) {
                Notification.errro(resp.data.messages);
            });
        }
        vm.submit = submit;
    }
    angular
        .module('app')
        .controller('ForgotPasswordCtrl', ForgotPasswordCtrl);

    ForgotPasswordCtrl.$inject = ['$stateParams', 'userService', '$state', 'Notification'];
})();