(function () {
    'use strict';

    function UserProfileAccountCtrl($location) {
        /* jshint validthis:true */
        var vm = this;
    }

    angular
        .module('app')
        .controller('UserProfileAccountCtrl', UserProfileAccountCtrl)

    UserProfileAccountCtrl.$inject = ['$location'];
})();