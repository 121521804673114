(function () {
    'use strict';

    angular
        .module('app')
        .controller('RegistrationCtrl', RegistrationCtrl);

    RegistrationCtrl.$inject = ['$state', 'userService', 'Notification'];

    function RegistrationCtrl($state, userService, Notification) {
        var vm = this;
        vm.registrationData = {};
        vm.submit = submit;
        vm.back = back;
        function back() {
            $state.go('login');
        }

        function submit() {
            vm.registrationData.role = '1';
            userService.register(vm.registrationData).then(function (resp) {
                Notification.success(resp.data.message);
                $state.go('login');
            }, function (error) {
                Notification.error(error.data.message);
            });
        }
    }
})();