
(function () {
    'use strict';

    angular
        .module('app')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', '$state', '$localStorage', '$http', 'authService'];

    function HeaderController($scope, $state, $localStorage, $http, authService) {
        var vm = this;
        $scope.$on('$includeContentLoaded', function () {
            Layout.initHeader(); // init header
        });

        function logOut() {
            authService.clearToken();
            authService.clearUser();
            $localStorage.$reset();
            $http.defaults.headers.common.Authorization = '';
            $scope.user = null;
            $state.go('login');
        }

        vm.logOut = logOut;
    }
})();